<template>
  <div class="modalPopup" id="scheduleRegist_modal" v-if="modalClose">
    <div class="modal_header">
      <h3 class="title">일정등록</h3>
      <button class="modal_close" @click="isModalClose"></button>
    </div>
    <div class="modal_body">
      <div class="options">
        <ul>
          <li
            v-for="(data, index) in filterData !== undefined ||
            filterData.length > 0
              ? filterData.filter(x => x.input_date == this.dateString2)
              : ''"
            :key="data.id"
          >
            <label
              class="label_txt"
              :class="`select_${data.system_event_type_id2}`"
            ></label>
            <p>
              {{ data.title }}<span>{{ data.detail }}</span>
            </p>
            <button
              type="button"
              class="calendar_link"
              v-if="data.delete_id !== null"
              @click="selectCalendarEvent(data, index)"
            ></button>
            <button
              type="button"
              class="input_delete"
              v-if="data.delete_id !== null && validUser"
              @click="deleteCalendarEvent(data, index)"
            ></button>
          </li>
        </ul>
      </div>
      <div class="modal_contents">
        <div class="regist_options">
          <span class="date">{{ dateString }}</span>
          <my-local-selectric
            v-if="showSelectric"
            :id="'calenderSelect'"
            :options="filterOptions"
            :watch="selectric_value"
            @changeValue="setSelectricValue($event)"
            :state="!validUser ? 'disabled' : ''"
          >
            <label
              slot="default_label"
              class="label_txt"
              :class="selectClass"
            ></label>
            <label slot="option_label" class="label_txt"></label>
          </my-local-selectric>
        </div>
        <div class="input_text">
          <label>제목</label>
          <input
            type="text"
            placeholder="일정 제목 입력"
            :value="calendarModalData.title"
            @input="setModalTitle"
            :readonly="!validUser"
          />
        </div>
        <div class="input_text">
          <label>메모</label>
          <input
            type="text"
            placeholder="메모 입력"
            :value="calendarModalData.detail"
            @input="setModalDetail"
            :readonly="!validUser"
          />
        </div>
      </div>
    </div>
    <div class="modal_footer">
      <div class="warning" v-show="error_text != ''">
        {{ error_text }}
      </div>
      <button class="btn_sub2" @click="submit" :disabled="!validUser">
        저장
      </button>
      <button type="button" class="btn_cancel">
        취소
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import SPINNER_MIXIN from '@/mixins/spinner';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [SPINNER_MIXIN, FETCH_MIXIN, DRAG_MODAL_MIXIN, FavoriteMixin],
  components: { MyLocalSelectric },
  props: {
    filterData: {
      type: Array,
      required: true,
    },
    dateString: {
      type: String,
      required: true,
    },
    dateString2: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalClose: true,
      error_text: '',
      selectric_value: null,
      showSelectric: false,
      selectClass: 'select_1',
    };
  },
  computed: {
    ...mapGetters({
      calendarOptions: 'getCalendarOptionsFromCalendar',
      calendarOptionsMain: 'getCalendarOptionsFromMainCalendar',
      calendarModalData: 'getCalendarModalDataFromCalendar',
    }),
    filterOptions() {
      let clone = [];
      if (this.calendarOptions < 1) {
        clone = this.lodash.clonedeep(this.calendarOptionsMain);
      } else {
        clone = this.lodash.clonedeep(this.calendarOptions);
      }
      if (clone.map(x => x.name).includes('관리자 등록')) {
        clone.splice(clone.length - 1, 1);
      }
      clone.forEach(el => {
        el.value = el.id;
        el.label = el.name;
      });
      return clone;
    },
    validUser() {
      const findUserRole = this.users.find(x => x.account == this.userId)
        .user_role_type_id;

      if (findUserRole == 1) {
        return true;
      } else return false;
    },
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
      this.calendarModalData.title = '';
      this.calendarModalData.detail = '';
      this.calendarModalData.system_event_type_id = 1;

      this.$store.commit('setCalendarModalDataToCalendar', {
        id: '',
        detail: '',
        title: '',
        delete_id: null,
        system_event_type_id: 1,
      });
    },
    setSelectricValue(value) {
      this.selectric_value = value;
      this.selectClass = `select_${value}`;
      this.calendarModalData.system_event_type_id = value;
    },
    setModalTitle(e) {
      this.calendarModalData.title = e.target.value;
      console.log(this.dateString2);
    },
    setModalDetail(e) {
      this.calendarModalData.detail = e.target.value;
    },
    InitModal() {
      this.$store.commit('setCalendarModalDataToCalendar', {
        id: '',
        detail: '',
        title: '',
        delete_id: null,
        system_event_type_id: 1,
      });
    },
    async selectCalendarEvent(data) {
      this.$store.commit('setCalendarModalDataToCalendar', data);
      this.setSelectricValue(data.extendedProps.status);
    },
    async submit() {
      this.calendarModalData.input_date = this.dateString2;

      let data = this.lodash.clonedeep(this.calendarModalData);
      console.log('data', data);
      if (data.delete_id != null) {
        await this.$store
          .dispatch('UPDATE_CALENDAR_MODAL', data)
          .then(async () => {
            this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
            await this.FETCH_WITH_PAYLOAD(
              'FETCH_CALENDAR_MONTH',
              this.dateString2.substr(0, 7),
              '일정',
            );
          })
          .catch(error => {
            this.openOneButtonModal('저장 중 오류', '주요 일정 저장중 오류');
            console.log(error);
          })
          .finally(() => {
            this.hideSpinner();
            this.isModalClose();
            this.InitModal();
          });
      } else {
        if (data.title == '' || data.title == undefined) {
          this.error_text = '제목을 입력해주세요.';
        } else {
          this.showSpinner();
          await this.$store
            .dispatch('INSERT_CALENDAR_MODAL', data)
            .then(async () => {
              this.openOneButtonModal(
                '저장 성공',
                '성공적으로 저장하였습니다.',
              );
              await this.FETCH_WITH_PAYLOAD(
                'FETCH_CALENDAR_MONTH',
                this.dateString2.substr(0, 7),
                '일정',
              );
            })
            .catch(error => {
              this.openOneButtonModal('저장 중 오류', '주요 일정 저장중 오류');
              console.log(error);
            })
            .finally(() => {
              this.hideSpinner();
              this.isModalClose();
              this.InitModal();
            });
        }
      }
    },
    async deleteCalendarEvent(value, index) {
      try {
        this.showSpinner();
        this.$store
          .dispatch('DELETE_CALENDAR_MODAL', value.delete_id)
          .then(() => {
            const newFilterData = this.lodash.clonedeep(
              this.filterData.filter(x => x.input_date == this.dateString2),
            );
            newFilterData.splice(index, 1);
            this.$store.commit('setCalendarMonthToCalendar', newFilterData);
            this.$store.commit('setCalendarMonthToMainCalendar', newFilterData);
            this.InitModal();
          })
          .catch(() => {
            this.openOneButtonModal(
              '삭제 오류',
              '일정을 삭제 중 오류가 발생하였습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } catch (error) {
        console.log(error);
        this.openOneButtonModal(
          '삭제 오류',
          '일정을 삭제 중 오류가 발생하였습니다.',
        );
        this.hideSpinner();
      }
    },
  },
  async created() {
    if (this.calendarOptions.length < 1) {
      this.selectric_value = this.calendarOptionsMain[0].id;
    } else {
      this.selectric_value = this.calendarOptions[0].id;
    }
    this.showSelectric = true;
    this.InitModal();
  },
};
</script>

<style lang="scss" scoped></style>
