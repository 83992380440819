<template>
  <div
    id="contents"
    :class="{
      status_board: tabIndex == 0,
      plc_status_board: tabIndex == 0,
      schedule_management: tabIndex == 1,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToStatusMonitorPage'"
          :options="[
            { title: 'PLC 현황판', name: 'pcl_status_board' },
            { title: '작업 일정 관리', name: 'calendar_management' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <!-- <button class="btn_admin">저장</button> -->
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">PLC 현황판</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">작업 일정 관리</a>
          </li>
        </ul>
      </div>
      <plc-status-board-form v-if="tabIndex == 0"></plc-status-board-form>
      <calendar-management-form v-if="tabIndex == 1"></calendar-management-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import ClosePageMixin from '@/mixins/closePage';
import PlcStatusBoardForm from '@/views/forms/Custom/Monitor/6/PlcStatusBoardForm';
import CalendarManagementForm from '@/views/forms/Custom/Monitor/6/CalendarManagementForm';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    PlcStatusBoardForm,
    CalendarManagementForm,
  },
  data() {
    return {
      swiper: null,
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToStatusMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitStatusMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'gumsan_monitoring';
</style>
