var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article",class:{ active: _vm.fullScreen == true }},[_c('div',{staticClass:"tbl_wrap"},[_c('div',{staticClass:"tbl_option"},[_c('h5',[_vm._v(" 측정시간 : "+_vm._s(_vm.find당침기() == null ? '' : _vm.yyyyMMddHHmmss(new Date(_vm.find당침기().create_time)))+" ")]),_c('div',{staticClass:"btn_wrap"},[_c('button',{staticClass:"btn_sub1 refresh_btn",on:{"click":function($event){_vm.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
            _vm.FETCH_NO_SPINNER('FETCH_PLC_SENSOR', 'PLC 센서');}}},[_vm._v(" 재조회 ")]),_c('button',{staticClass:"btn_sub1",on:{"click":function($event){_vm.fullScreen = !_vm.fullScreen}}},[_vm._v(" "+_vm._s(_vm.fullScreen ? "닫기" : '전체화면')+" ")])])]),_c('div',{staticClass:"monitor_wrap"},[_c('div',{staticClass:"monitor_body"},[_c('div',{staticClass:"monitor_card",class:{ warning_card: !_vm.check당침기state }},[_c('h2',[_vm._v("당침기")]),_c('div',{staticClass:"gag_periods"},[_c('div'),_c('span',{class:{
                normal_point: _vm.check당침기state,
                warning_point: _vm.check당침기state == false,
              }},[_vm._v(_vm._s(_vm.check당침기state ? '정상' : '경고'))])]),_c('p',[_vm._v(_vm._s(_vm.find당침기() == null ? '' : _vm.find당침기().value)+"℃")])]),_c('div',{staticClass:"monitor_card",class:{ warning_card: !_vm.check증삼기state }},[_c('h2',[_vm._v("증삼기")]),_c('div',{staticClass:"increase"},[_c('div'),_c('span',{class:{
                normal_point: _vm.check증삼기state,
                warning_point: _vm.check증삼기state == false,
              }},[_vm._v(_vm._s(_vm.check증삼기state ? '정상' : '경고'))])]),_c('p',[_vm._v(_vm._s(_vm.find증삼기() == null ? '' : _vm.find증삼기().value)+"℃")])]),_c('div',{staticClass:"monitor_card",class:{
            warning_card:
              _vm.find건조실() != null &&
              (!_vm.check건조실온도state || !_vm.check건조실습도state),
          }},[_c('h2',[_vm._v("건조실")]),_c('div',{staticClass:"drying_room"},[_c('div'),_c('span',{class:{
                normal_point: _vm.check건조실온도state && _vm.check건조실습도state,
                warning_point:
                  _vm.check건조실온도state == false ||
                  _vm.check건조실습도state == false,
              }},[_vm._v(_vm._s(_vm.find건조실() == null || (_vm.check건조실온도state && _vm.check건조실습도state) ? '정상' : '경고'))])]),_c('p',[_vm._v(" "+_vm._s(_vm.find건조실() == null ? '' : _vm.find건조실().온도.value)+"℃ / "+_vm._s(_vm.find건조실() == null ? '' : _vm.find건조실().습도.value)+"% ")])]),_c('div',{staticClass:"monitor_card"},[_c('h2',[_vm._v("금속검출기")]),_c('div',{staticClass:"metal_detector"},[_c('div'),_c('span',[_vm._v(_vm._s(_vm.find금속검출기() == null ? '' : _vm.find금속검출기().불량.value)+"EA")])]),_c('p',[_vm._v("불량수량")])]),_c('div',{staticClass:"monitor_card",class:{
            warning_card: _vm.find보일러실() != null && _vm.find보일러실().value == 1,
          }},[_c('h2',[_vm._v("보일러실")]),_c('div',{staticClass:"boiler_room"},[_c('div'),_c('span',{class:{
                normal_point:
                  _vm.find보일러실() != null && _vm.find보일러실().value != 1,
                warning_point:
                  _vm.find보일러실() != null && _vm.find보일러실().value == 1,
              }},[_vm._v(_vm._s(_vm.find보일러실() == null ? '' : _vm.find보일러실().value == 1 ? '미작동' : '작동'))])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }