<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5>
          측정시간 :
          {{
            find당침기() == null
              ? ''
              : yyyyMMddHHmmss(new Date(find당침기().create_time))
          }}
        </h5>
        <div class="btn_wrap">
          <button
            class="btn_sub1 refresh_btn"
            @click="
              FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
              FETCH_NO_SPINNER('FETCH_PLC_SENSOR', 'PLC 센서');
            "
          >
            재조회
          </button>
          <button class="btn_sub1" @click="fullScreen = !fullScreen">
            {{ fullScreen ? `닫기` : '전체화면' }}
          </button>
        </div>
      </div>
      <div class="monitor_wrap">
        <div class="monitor_body">
          <div
            class="monitor_card"
            :class="{ warning_card: !check당침기state }"
          >
            <h2>당침기</h2>
            <div class="gag_periods">
              <div></div>
              <span
                :class="{
                  normal_point: check당침기state,
                  warning_point: check당침기state == false,
                }"
                >{{ check당침기state ? '정상' : '경고' }}</span
              >
            </div>
            <p>{{ find당침기() == null ? '' : find당침기().value }}℃</p>
          </div>
          <div
            class="monitor_card"
            :class="{ warning_card: !check증삼기state }"
          >
            <h2>증삼기</h2>
            <div class="increase">
              <div></div>
              <span
                :class="{
                  normal_point: check증삼기state,
                  warning_point: check증삼기state == false,
                }"
                >{{ check증삼기state ? '정상' : '경고' }}</span
              >
            </div>
            <p>{{ find증삼기() == null ? '' : find증삼기().value }}℃</p>
          </div>
          <div
            class="monitor_card"
            :class="{
              warning_card:
                find건조실() != null &&
                (!check건조실온도state || !check건조실습도state),
            }"
          >
            <h2>건조실</h2>
            <div class="drying_room">
              <div></div>
              <span
                :class="{
                  normal_point: check건조실온도state && check건조실습도state,
                  warning_point:
                    check건조실온도state == false ||
                    check건조실습도state == false,
                }"
                >{{
                  find건조실() == null ||
                  (check건조실온도state && check건조실습도state)
                    ? '정상'
                    : '경고'
                }}</span
              >
            </div>
            <p>
              {{ find건조실() == null ? '' : find건조실().온도.value }}℃ /
              {{ find건조실() == null ? '' : find건조실().습도.value }}%
            </p>
          </div>
          <div class="monitor_card">
            <h2>금속검출기</h2>
            <div class="metal_detector">
              <div></div>
              <span
                >{{
                  find금속검출기() == null ? '' : find금속검출기().불량.value
                }}EA</span
              >
            </div>
            <p>불량수량</p>
          </div>
          <div
            class="monitor_card"
            :class="{
              warning_card: find보일러실() != null && find보일러실().value == 1,
            }"
          >
            <h2>보일러실</h2>
            <div class="boiler_room">
              <div></div>
              <span
                :class="{
                  normal_point:
                    find보일러실() != null && find보일러실().value != 1,
                  warning_point:
                    find보일러실() != null && find보일러실().value == 1,
                }"
                >{{
                  find보일러실() == null
                    ? ''
                    : find보일러실().value == 1
                    ? '미작동'
                    : '작동'
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      str_time: null,
      timerCount: 300,
      timerMax: 300,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensors: 'getPlcSensor',
      plc_sensor_logs: 'getPlcSensorLog',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),

    check당침기state() {
      const item = this.find당침기();

      if (this.plc_sensors.length < 1) {
        return true;
      } else {
        if (item == null) {
          return true;
        }
        const foundSensor = this.plc_sensors.find(
          x => x.id == item.plc_sensor_id,
        );

        if (foundSensor == undefined) {
          return true;
        } else {
          if (
            this.$makeNumber(foundSensor.min_value) >
              this.$makeNumber(item.value) ||
            this.$makeNumber(foundSensor.max_value) <
              this.$makeNumber(item.value)
          ) {
            return false;
          } else return true;
        }
      }
    },
    check증삼기state() {
      const item = this.find증삼기();
      if (this.plc_sensors.length < 1) {
        return true;
      } else {
        if (item == null) {
          return true;
        }
        const foundSensor = this.plc_sensors.find(
          x => x.id == item.plc_sensor_id,
        );
        if (foundSensor == undefined) {
          return true;
        } else {
          if (
            this.$makeNumber(foundSensor.min_value) >
              this.$makeNumber(item.value) ||
            this.$makeNumber(foundSensor.max_value) <
              this.$makeNumber(item.value)
          ) {
            return false;
          } else return true;
        }
      }
    },
    check건조실온도state() {
      if (this.find건조실() == null) {
        return true;
      }
      const item = this.find건조실().온도;
      if (this.plc_sensors.length < 1) {
        return true;
      } else {
        if (item == null) {
          return true;
        }
        const foundSensor = this.plc_sensors.find(
          x => x.id == item.plc_sensor_id,
        );
        if (foundSensor == undefined) {
          return true;
        } else {
          if (
            this.$makeNumber(foundSensor.min_value) >
              this.$makeNumber(item.value) ||
            this.$makeNumber(foundSensor.max_value) <
              this.$makeNumber(item.value)
          ) {
            return false;
          } else return true;
        }
      }
    },
    check건조실습도state() {
      if (this.find건조실() == null) {
        return true;
      }
      const item = this.find건조실().습도;
      if (this.plc_sensors.length < 1) {
        return true;
      } else {
        if (item == null) {
          return true;
        }
        const foundSensor = this.plc_sensors.find(
          x => x.id == item.plc_sensor_id,
        );
        if (foundSensor == undefined) {
          return true;
        } else {
          if (
            this.$makeNumber(foundSensor.min_value) >
              this.$makeNumber(item.value) ||
            this.$makeNumber(foundSensor.max_value) <
              this.$makeNumber(item.value)
          ) {
            return false;
          } else return true;
        }
      }
    },
  },
  methods: {
    playWarningSound() {
      const audio = new Audio(require('@/assets/sound/warning_sound.mp3'));
      audio.play();
    },
    yyyyMMddHHmmss(date) {
      var MM = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();
      var hh = date.getHours();
      var mm = date.getMinutes();
      var ss = date.getSeconds();

      return [
        date.getFullYear(),
        '-' + (MM > 9 ? MM : '0' + MM),
        '-' + (dd > 9 ? dd : '0' + dd),
        ' (' + (hh > 9 ? hh : '0' + hh),
        ':' + (mm > 9 ? mm : '0' + mm),
        ':' + (ss > 9 ? ss : '0' + ss),
        ')',
      ].join('');
    },

    find보일러실() {
      if (this.plc_sensor_logs.length < 1) {
        return null;
      } else {
        const 보일러실 = this.plc_sensor_logs.find(x => x.plc_sensor_id == 9);
        return 보일러실 == undefined ? null : 보일러실;
      }
    },
    find당침기() {
      if (this.plc_sensor_logs.length < 1) {
        return null;
      } else {
        const 당침기 = this.plc_sensor_logs.find(x => x.plc_sensor_id == 4);
        return 당침기 == undefined ? null : 당침기;
      }
    },
    find증삼기() {
      if (this.plc_sensor_logs.length < 1) {
        return null;
      } else {
        const 증삼기 = this.plc_sensor_logs.find(x => x.plc_sensor_id == 3);
        return 증삼기 == undefined ? null : 증삼기;
      }
    },
    find건조실() {
      if (this.plc_sensor_logs.length < 1) {
        return null;
      } else {
        const 건조실온도 = this.plc_sensor_logs.find(x => x.plc_sensor_id == 5);
        if (건조실온도 == undefined) {
          return null;
        }
        const 건조실습도 = this.plc_sensor_logs.find(x => x.plc_sensor_id == 6);
        if (건조실습도 == undefined) {
          return null;
        }
        return {
          온도: 건조실온도,
          습도: 건조실습도,
        };
      }
    },
    find금속검출기() {
      if (this.plc_sensor_logs.length < 1) {
        return null;
      } else {
        const 통과수량 = this.plc_sensor_logs.find(x => x.plc_sensor_id == 7);
        if (통과수량 == undefined) {
          return null;
        }
        const 불량수량 = this.plc_sensor_logs.find(x => x.plc_sensor_id == 8);
        if (불량수량 == undefined) {
          return null;
        }
        return {
          통과: 통과수량,
          불량: 불량수량,
        };
      }
    },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    // clearInterval(this.currentTimeInterval);
  },
  watch: {
    check당침기state(newValue) {
      if (newValue == false) {
        this.playWarningSound();
      }
    },
    check증삼기state(newValue) {
      if (newValue == false) {
        this.playWarningSound();
      }
    },
    check건조실온도state(newValue) {
      if (newValue == false) {
        this.playWarningSound();
      }
    },
    check건조실습도state(newValue) {
      if (newValue == false) {
        this.playWarningSound();
      }
    },
  },
  async created() {
    if (this.plc_sensors.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', 'PLC 센서');
    }

    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;
      console.log('test');
      if (this.tabIndex == 0) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR', 'PLC 센서');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    // this.currentTimeInterval = setInterval(() => {
    //   this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    // }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
